import * as React from "react"
import Seo from "@components/seo"
import Layout from "@layout"
import Header from "@layout/header/header-one"
import Footer from "@layout/footer/footer-one"
import ProceedingsArea from "@containers/proceedings-area"
import Banner from "@components/banners/Ai";


const ProceedingsPage = ({pageContext, location }) => (
  <Layout location={location}>
    <Seo title="Publications of Proceedings" />
    <Header />
    { <Banner
			pageContext={pageContext}
			location={location}
			title="The Publications of Proceedings"
		/> }
    <main className="site-wrapper-reveal">
      <ProceedingsArea />
    </main>
    <Footer />
  </Layout>
)

export default ProceedingsPage
